

.footersession>hr{
   
   border:1px solid var(--lightgray)
}

.footer-content i{
  color: white;
  font-size: 1.5rem;
  
}
.footer-details{
    color: white;
    font-size: 1rem;  
    padding: 0px 10px;
    line-height: 30px;
    letter-spacing: 1px;
}
/* .logo-f{
    width: 10rem;
    height: 4rem;
 
    
} */
.copyright{
    color: white;
    text-align: center;
    padding-bottom: 2rem;
}
/* .footercontent{
    padding: 1rem  2rem;
    display: flex;
    flex-direction: column;
    gap:4rem;
    justify-content: center;
    align-items: center;
    height: 20rem;

}
.sociallinks{
    display: flex;
    gap:1rem;
}

.sociallinks>svg{
    width: 2rem;
    height:2rem;
   fill:white;
   cursor: pointer;
 }
 .sociallinks>span{
    color: white;
    font-size: 15px;
    font-weight: bold;
 }

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    background:yellow;
}
.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    background:yellow;
}
@media screen and (max-width:768px){
    .sociallinks{
        flex-direction: column;
  
     
    }
} */