.hero {
    display: flex;
    justify-content: space-between;
}

.left-h {
    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;

}

.right-h {
    flex: 1.5;
    position: relative;
    background-color: var(--green);
}

.menu-ad {
    margin-top: 4rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 20px 13px;
    text-transform: uppercase;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menu-ad>span {
    z-index: 2;

}

.menu-ad>div {
    position: absolute;
    background-color: var(--green);
    width: 5.4rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-txt {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-txt>div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
    padding-bottom: 1rem;
}

.hero-buttons {
    display: flex;
    gap: 2rem;
    font-weight: normal;
}

.hero-buttons>:nth-child(1) {
    color: white;
    background-color: var(--green);
    width: 8rem;
}

.hero-buttons>:nth-child(2) {
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--green)
}

.right-h>.btns {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: black;
}

.hero-image {
    position: absolute;
    /* top:1rem; */
    right: -12rem;
    width: 55rem;
    z-index: 2;
    bottom: 0;
}

.hero-image-bg {
    position: absolute;
    top: 4rem;
    right: 26rem;
    z-index: 1;
}

.hero-blur {
    width: 22rem;
    height: 30rem;
    left: 0;
}

/* @media screen and (max-width: 1280px) {
    .hero-image {
    
            right: -14rem;
            width: 50rem;
        }
        .hero-image-bg{
            top:6rem;
            right: 20rem;

        }
        .hero-txt{
            gap:1rem;
            text-transform: uppercase;
            font-size: 3.5rem; 
        }
        
        .hero-txt>div:nth-of-type(3){
            width: 60%;
        }
}
@media screen and (max-width: 1024px) {
    .hero-image {
    
            right: -14rem;
            width: 45rem;
        }
        .hero-image-bg{
            top:6rem;
            right: 15rem;

        }
        .hero-txt{
            gap:1rem;
            text-transform: uppercase;
            font-size: 3.5rem; 
        }
        
        .hero-txt>div:nth-of-type(3){
            width: 60%;
        }
}
@media screen and (max-width: 768px) {
    .hero-image {
    
        right: -8rem;
        width: 30rem;
    }


    .hero-image-bg{
        top:4rem;
        right: 10rem;
        height: 15rem;

    }
    .hero-txt{
        gap:1rem;
        text-transform: uppercase;
        font-size: 1.5rem; 
    }
    
    .hero-txt>div:nth-of-type(3){
        width: 80%;
        font-size: small;
    }
    .menu-ad{
     padding: 13px 13px;
    font-size: 10px;
    }
    .menu-ad>div{
      
        width: 4.4rem;
        height:70%;
       
        }
        .hero-buttons>:nth-child(1),
        .hero-buttons>:nth-child(2){
           
            width: fit-content;
            font-size: small;
        }  

        .right-h>.btns{
            width: fit-content;
            font-size: small;
        }
        
}

@media screen and (max-width: 640px) {
    .hero-image {
        right: -10rem;
    }
    .hero-image-bg {
        right: 8rem;
    }


}
@media screen and (max-width: 475px) {
    .hero-image {
        right: -10rem;
        width: 20rem;
    }
    .hero-image-bg {
        right: 4rem;
        width: 4rem;
        height: 8rem;
    }


} */
@media screen and (max-width:768px) {
    .hero {
        flex-direction: column;
        z-index: 4;
    }

    .menu-ad {
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }

    .hero-txt {
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        gap: 1rem;
    }

    .hero-txt>div:nth-of-type(3) {
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
        padding: 1rem;
    }

    .hero-buttons {
        justify-content: center;
    }

    .hero-image {
        position: relative;
        ;
        align-self: center;
        height: 15rem;
        width: 25rem;
        right: 2rem;
    }

    .right-h {
        right: 0;
        left: 8rem;
        width: 90%;

    }

    .hero-image {
        left: -5rem;

    }

    .hero-image-bg {
        right: 17rem;
        top: 1rem;
        height: 90%;
    }

}