.header {
    display: flex;
    justify-content: space-between;
    z-index: 5;
    padding: 10px 20px;
}

.logo {
    width: 8rem;
    height: 4rem;
}

.routinglink {
    text-decoration: none;
    color: white;
}

.headermenu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
    cursor: pointer;
    justify-content: flex-end;
}

.headermenu>li:hover {
    cursor: pointer;
    color: var(--green);
}

.nav-item .nav-link.white {
    color: white;
    text-decoration: none;
}

.dropdown {
    display: flex;
    gap: 10px;
}

.dropdown-menu {
    background-color: var(--green);
    border: none;


}

.dropdown-item {
    color: white;
}

@media screen and (max-width:768px) {
    .header{
        padding:0px 0px;
    }
    .headermenu {
        display: block;
        font-size: 15px;
        font-weight: bold;

    }
   .headermenu li {
        padding: 10px 10px;
    }
}