.top-btn{
    color: white;
    font-size: 1.5rem;
    background-color: var(--green);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.scrolltotop{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  
}
.fa-arrow-up{
    animation: gototop 1.2s linear infinite alternate-reverse;
}

@keyframes gototop{
    0%{
        transform: translateY(-5px);
    }
    100%{
        transform: translateY(5px);
    }
}

@media screen and (max-width:768px){
    .top-btn {
        right: 5%;
       
      }
}