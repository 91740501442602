.careersession {
    display: flex;
    flex-direction: column;
    /* margin-top:-10rem; */
    padding: 0 2rem;
    gap: 4rem;
    position: relative;
    margin-top: 4rem;
}

.btns a {
    text-decoration: none;
    color: var(--appcolor);
}

.career {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;

}

.careercard {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
    background-color: var(--careerCard);
    color: white;
    font-size: 1rem;
    font-weight: bold;

}

.careercard:nth-child(2) {
    background: rgb(138, 205, 107);
    background: linear-gradient(203deg, rgba(138, 205, 107, 1) 25%, rgba(90, 170, 53, 1) 95%);
    transform: scale(1.1);

}

.careercard:nth-child(2)>button {
    color: var(--green);
}

.details {
    display: flex;
    align-items: center;
    gap: 0.5rem
}

.details>:nth-child(1) {
    width: 1.3rem;
    height: 1rem;

}

.careercard>img {
    width: 2rem;
    height: 2rem;
}

.career-blur-1 {
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0rem;
}

.career-blur-2 {
    width: 32rem;
    height: 23rem;
    top: 10rem;
    right: 0rem;
}

@media screen and (max-width:768px) {
    .details-header {
        font-size: x-large;
    }

    .career {
        flex-direction: column;
    }

    .careercard:nth-child(2) {
        transform: none;
    }

    .careersession {
        margin-top: 2rem;
    }

}