.services{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding: 0 2rem;
    margin-top: 4rem;
}

.service-header{
    display: flex;
    gap:5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color:white;
    text-transform: uppercase;
    font-style: italic;
}
.service-catagories{
    color: white;
    display: flex;
    gap:1rem;
}
.category>img{
    width: 2rem;
}

.know-more>img{
    width: 1rem;
    height: 1rem;
    
}
.category{
    display: flex;
    flex-direction: column;
    gap:2rem;
    justify-content: space-between;
    background-color: gray;
    padding: 2rem;
}

.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.category>:nth-child(3){
    font-size: 0.9rem;
   line-height: 25px;
}

.know-more{
    display: flex;
    align-items: center;
    gap:2rem;
    font-size: 0.9rem;
}

.category:hover{
    background-color: var(--serviceCard);
}

@media screen and (max-width:768px){
    .service-header{
        flex-direction: column;
        gap:1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
       
    }
 .services{
    margin-top:2rem;
 }
    .service-catagories {
       flex-direction: column; 
    }
}