.service-container {
    width: 100%;

}
/* .service-bg{
    width: 100%;
    height:90vh;
    object-fit: cover;
} */
.service-detail-banner {
    height:80%;
    width: 100%;
}
.service-bg{
   
    width: 100%;
   height: auto;
   object-fit: cover;
}

.our-services {
    width: 80%;
    height:fit-content;
    /* background-color: var(--careerCard); */
    /* border: 2px solid var(--gray); */
    margin: 50px;
}

.content-service {
    padding: 10px 38px;

}
.service-banner-content h3{
font-size: 1.5rem;
text-transform: capitalize;
}

.heading-service {
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin: 10px 0px;
    margin-bottom: 34px;
    position: relative;
}

.heading-service::after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -8px;
    background-color: var(--green);
    width: 50px;
    height: 2px;
}

.para-service {
    font-size: 15px;
    font-weight: 500;
    color: var(--lightgray);
    line-height: 21px;
    margin: 25px 0px;
    text-align: justify;
}

.services-contents {
    flex-wrap: wrap;
}

.img-service {
    height:320px;
    width: 100%;
    object-fit: cover;
    /* position: absolute;
    top:0;
    right:20px; */
}

.lh-lg {
    line-height: 1.7 !important;
}

.circle-icon {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--green);
}


#sec-3 {
    background-color: var(--green);
}

#sec-3 i {
    color: var(--green);
}

#sec-4 {
    color: white;
}

.product-points img {
    width: 1rem;
    height: 1rem;
}

.product-points {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.service-image {
    width: 400px;
    height: 300px;
    object-fit: cover;
}
.card{
    background-color: transparent;
 
}

@media screen and (max-width: 768px) {
    .servicepanel {

        flex-direction: column-reverse;

    }
 
    .service-detail-banner {
        height:80%;
        width: 100%;
    }
    .service-bg{
       
        width: 100%;
       height: auto;
       object-fit: cover;
    }
    
}

