/* 
.product-container{
    margin: 50px 0px;
}
.navbar-brand .fa{
    font-size: 1.3rem;
}
.nav-icons p{
    font-size: 1rem;
    margin-right: 0.6rem;
}

.btn-header:hover{
    background-color: #fff;
    color: var(--green)!important;
}
.phone-img img{
    width: 500px;
} */


/* older section ends */
.product-detail{
    height: 80vh;
   
}


.fa-arrow-circle-right{
font-size: 1rem;
padding: 0px 10px;
color: var(--green);
}
.productbutton{
   padding: 0px 40px;
}
.productbanner{
    height:auto;
    width:100%
}
.product-heroheader{
    font-size: 2rem;
    letter-spacing: 3px;
}
.product-heropara{
    font-size: 15px;
    color: white;
    letter-spacing: 2px;
    line-height: 30px;
    text-align: justify;
    padding: 10px 10px;
}
.product-detail1-content{
    color: white;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 30px;
}


.product-detail1-content h3{
    text-align: center;
    position: relative;
}

.product-detail1-content h3::after{
   content: "";
   width: 80px;
   height: 2px;
   background-color: var(--green);
   position: absolute;
   bottom: -10px;
   left:50%
}
.readmore{
    display: inline-block;
   margin: 20px 10px;
    color: var(--green);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

/* image background */
.product-image{
    position: relative;
    width: 400px;
    height: 400px;
 
}
.green-content{
    width: 300px;
    height: 300px;
    background-color: var(--green);
    position: absolute;
    left: 4rem;
    top:2rem;
  z-index:90;
  animation: productbg 1.2s linear infinite alternate-reverse;
    
}
.green-border{
    width: 300px;
    height: 300px;
    border: 2px solid var(--green);
    position: absolute;
   right:7rem;
   top:-2rem;
   animation: productbg1 1.2s linear infinite alternate-reverse;
}
@keyframes productbg1{
    0%{
        transform: translateX(15px);
    }
    100%{
        transform: translateX(-15px);
    }
}

/* @keyframes productbg{
    0%{
        transform: translatey(-15px);
    }
    100%{
        transform: translatey(15px);
    }
} */
.product-image img{
    height: 300px;
    width:300px;
    object-fit: cover;
    z-index: 100;
    position: absolute;
    transform: scale(1.1);
    margin: 0px 20px;
}



 .product-detail{
    height: 80vh;
    background-color: var(--green);
}
.product-container{
    margin: 50px 0px;
}
.navbar-brand .fa{
    font-size: 1.3rem;
}
.nav-icons p{
    font-size: 1rem;
    margin-right: 0.6rem;
}

.btn-header:hover{
    background-color: #fff;
    color: var(--green)!important;
}
.phone-img img{
    width: 500px;
}

.product-detail1-content{
    color: white;
    text-align: justify;
    letter-spacing: 1px;
    line-height: 30px;
}


.product-detail1-content h3{
    text-align: center;
    position: relative;
}

.product-detail1-content h3::after{
   content: "";
   width: 80px;
   height: 2px;
   background-color: var(--green);
   position: absolute;
   bottom: -10px;
   left:50%
}
.readmore{
    display: inline-block;
   margin: 20px 10px;
    color: var(--green);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    background-color: transparent;
    border: none;
}

/* image background */
.product-image{
    position: relative;
    width: 400px;
    height: 400px;
 
}
.green-content{
    width: 300px;
    height: 300px;
    background-color: var(--green);
    position: absolute;
    left: 4rem;
    top:2rem;
  z-index:90;
 
    
}
.green-border{
    width: 300px;
    height: 300px;
    border: 2px solid var(--green);
    position: absolute;
   right:7rem;
   top:-2rem;
}
.product-image img{
    height: 300px;
    width:300px;
    object-fit: cover;
    z-index: 100;
    position: absolute;
    transform: scale(1.1);
    margin: 0px 20px;
}

@media  screen and (max-width: 768px) {
   .product-reverse{
    flex-direction: column-reverse;
    
   }
   .product-detail-hero{
    flex-direction: row-reverse;
   }
  }

