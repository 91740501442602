.left_a>img {
    /* width: 300px;
  height:300px; */

}

.aboutsession {
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
    margin-top: 4rem;
}

.left_a {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}


.left_a>:nth-child(1) {
    width: 15rem;
    grid-row: 1/3;
    height: 33rem;
    object-fit: cover;

}

.left_a>:nth-child(2) {
    width: 30rem;
    grid-column: 2/4;
    height: 20rem;
    object-fit: cover;

}

.left_a>:nth-child(3) {
    width: 15rem;
    grid-row: 2/3;
    height: 12rem;
    object-fit: cover;

}

.left_a>:nth-child(4) {
    width: 14rem;
    grid-column: 3/3;
    height: 12rem;
    object-fit: cover;

}

.right_a {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
}

.right_a>span {
    font-weight: bold;
    color: var(--green);
    font-size: 1.5rem;
}

.details-header {
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
}

.details-r img {
    width: 3rem;
    height: 3rem;
}

.details-r span {
    font-size: 15px;
    font-size: normal;
    color: white;
    text-transform:none;
}

@media screen and (max-width:768px) {
    .aboutsession {
        flex-direction: column;
        gap: 0.5rem;
       align-items: center;
    }
    .left_a {
        grid-template-columns: 150px 150px;
        gap: 0.5rem;

    }
    .left_a>:nth-child(1) {
        width: 10rem;
        grid-row: 1/2;
        height: 12rem;
        object-fit: contain;
    
    }
    .left_a>:nth-child(2) {
        width: 12rem;
       height: 12rem;
       object-fit: contain;

    }

    .left_a>:nth-child(3) {
        display: none;
    }

    .left_a>:nth-child(4) {
        width: 24rem;
        grid-column: 1/4;
        grid-row: 2;
        height: 12rem;
      
    }
}