.preloading{
    width: 100%;
    height: 100vh;
    background-color: var(--appColor);
    display: flex;
    justify-content: center;
    align-items: center;
}

.preloader {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    background: rgb(138, 205, 107);
    background: linear-gradient(203deg, rgba(138, 205, 107, 1) 25%, rgba(90, 170, 53, 1) 95%);

}


.content img{
    position: absolute;
    top:10px;
}
.preloader::before{
content: "";
position: absolute;
inset: -5px 5px;
transition: 0.5s;
animation: animatepreloader 4s linear infinite;
background-color: var(--appColor);
border-radius: 50%;
}
@keyframes animatepreloader{
0%
{
    transform: rotate(0deg);
}
100%{
    transform: rotate(360deg);
}
}
.content{
    position: absolute;
    inset:-10px 10px;
   
    border-radius: 50%;
    width: 80px;
    height: 80px;
    top:10px;
}