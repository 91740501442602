.contactsession{
    text-align: center;
    padding: 0 2rem;
    gap:10rem;
    margin-top: 4rem;
}
.left-contact{
    color:white;
    font-size:3rem;
    font-weight: bold;
    text-transform: uppercase;
    
}
.left-contact>hr{
    position: absolute;
    width: 10.5rem;
    border:4px solid var(--green);
    border-radius: 20%;
    margin:-10px 0; 
   left:35%
}






@media screen and (max-width:768px){
    .contactsession{
        flex-direction: column;
        gap:1rem;
        padding: 0 2rem;
        margin-top:2rem;
    }

    .left-contact{
        font-size: x-large;
        flex-direction: column;
    }
    /* .email-container{
       align-items: center;
       justify-content: center;
    } */
}